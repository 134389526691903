$(document).ready(function(){
    $('.menu button.botao-menu').click(function(){
        $(this).toggleClass('open');
        $('.hidden-menu').slideToggle();
        $("body").toggleClass('locked');
    });

    $('.card a').click(function () {
        $(this).toggleClass('flip');
        $(this).siblings('div.descricao').toggleClass('flip');
    });
    $('.card .descricao').click(function () {
        $(this).toggleClass('flip');
        $(this).siblings('a').toggleClass('flip');
    });

    $('.slide').slick({
        autoplay: true,
        autoplaySpeed: 5000,
        arrows: false,
        dots: true,
        fade: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
    });

    $('.slider-produtos').slick({
        autoplay: true,
        autoplaySpeed: 7000,
        arrows: true,
        centerMode: false,
        dots: false,
        prevArrow:'<button type="button" aria-label="Previous" class="slick-prev"><i class="fal fa-angle-left" aria-hidden="true"></i></button>',
        nextArrow:'<button type="button" aria-label="Next" class="slick-next"><i class="fal fa-angle-right" aria-hidden="true"></i></button>',
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
    });

    var SPMaskBehavior = function (val) {
        return val.replace(/\D/g, '').length === 11 ? '(00) 00000-0000' : '(00) 0000-00009';
    },
    spOptions = {
        onKeyPress: function(val, e, field, options) {
            field.mask(SPMaskBehavior.apply({}, arguments), options);
        }
    };
    $('input[name="phone"]').mask(SPMaskBehavior, spOptions);
    $('input[name="cel"]').mask(SPMaskBehavior, spOptions);
    $('input[name="whatsapp"]').mask(SPMaskBehavior, spOptions);

    // NAVEGAÇÃO MENU PRINCIPAL
    $(".menu li a").click(function(){
        $('body').removeClass('locked');
        $('.menu button.botao-menu').removeClass('open');
        $('.hidden-menu').hide();

        var secao = $(this).attr('href');
        var destino = secao.replace("/","");
        //var navegador = navigator.appName;
        //var tamanhoViewport = $(window).width();

        if (destino == "#topo") {
            $("html, body").animate({ scrollTop: 0 }, "slow");
        }
        else {
            $("html, body").animate({
                scrollTop: $(destino).offset().top
            }, 800);
        }
        return false;
    });

    // BOTÃƒO MAPA E CONTATO
    $("button.showMap").click(function(){
        $(".contato").fadeOut('slow');
        $(this).css('display','none');
        $("button.showForm").css('display','block');
    });
    $("button.showForm").click(function(){
        $(".contato").fadeIn('slow');
        $(this).css('display','none');
        $("button.showMap").css('display','block');
    });

    // BOTÃO VOLTAR AO TOPO
    $(".return-top a[href='#topo']").click(function() {
        $("html, body").animate({ scrollTop: 0 }, 800);
        return false;
    });

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();

        if (scroll >= 300) {
            $(".return-top").removeClass("hide");
        }
        else {
            $(".return-top").addClass("hide");
        }
    });
});