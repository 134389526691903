jQuery(document).ready(function () {
    jQuery('[data-ajax-submit ]').on('submit', function (event) {
        event.preventDefault();

        var that = jQuery(this);
        var submitButtonText = that.find('button:submit').text();

        jQuery.ajax({
            url: that.attr('action'),
            data: that.serialize(),
            dataType: 'json',
            type: that.attr('method'),
            beforeSend: function (jqXHR, settings) {
                that.find('.error-text').remove();
                that.find('button:submit').text('Enviando...');
                that.find('button:submit').prop('disabled', true);
                that.siblings('.alert').remove();
            }
        }).done(function (data, textStatus, jqXHR) {
            that.before('<div class="alert alert-success">Seu contato foi enviado com sucesso.</div>');

            if(that.is('[data-hide]')) {
                that.slideUp();
            }
        }).fail(function (jqXHR, textStatus, errorThrown) {
            data = jqXHR.responseJSON;
            if (!data.success) {
                if (typeof data.payload.validationErrors !== 'undefined') {
                    that.before('<div class="alert alert-danger">Contato não enviado, verifique os erros abaixo</div>');

                    var validation = data.payload.validationErrors;

                    validation.forEach(function (value) {
                        var field = (that.find('[name=' + value.field + ']'));
                        var validationElement = '<span class="error-text">' + value.message + '</span>';

                        if (field.parent().is('.input-group')) {
                            field.parent().parent().append(validationElement);
                        } else {
                            field.parent().append(validationElement);
                        }
                    });
                } else {
                    that.before('<div class="alert alert-danger">Parece que aconteceu algo de errado em nosso servidor tente novamente mais tarde</div>');
                }
            }
        }).always(function () {
            that.find('button:submit').text(submitButtonText);
            that.find('button:submit').prop('disabled', false);
        });
    });
});